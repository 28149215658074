html {
  font-family: Pretendard;
}

html,
body {
  height: 100%;
}

body,
ul,
h2,
h3,
h4 {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
}

* {
  color: black;
  box-sizing: border-box;
}
